<script>
import { defineComponent } from 'vue'
import DrawerAddress from '/~/components/drawer/views/personal-details/components/drawer-address.vue'

export default defineComponent({
  extends: DrawerAddress,
})
</script>

<template>
  <div
    class="relative flex justify-between rounded-xl border bg-white px-[15px] py-2.5"
  >
    <div class="space-y-1.5 text-sm" :class="processing && 'opacity-50'">
      <div v-if="address.companyName">
        {{ address.companyName }}
      </div>
      <div class="text-base font-bold text-eonx-neutral-800">
        {{ address.firstName }} {{ address.lastName }}
      </div>
      <div class="text-eonx-neutral-600">
        {{ address.streetAddress }}
      </div>
      <div class="text-eonx-neutral-600">
        {{ address.suburb }} {{ address.state.toUpperCase() }}
        {{ address.postcode }}
      </div>
      <div
        v-if="address.default"
        class="flex items-center font-bold"
        :style="{
          color: '#10B981',
        }"
      >
        <base-icon svg="plain/check" size="sm" class="mr-2.5" />
        Default
      </div>
    </div>
    <div
      class="mr-2.5 flex flex-shrink-0 flex-col items-end justify-center"
      :class="processing && 'opacity-50'"
    >
      <base-menu
        :items="menuItems(address)"
        position="right"
        class="-mr-2.5"
        :class="processing && 'pointer-events-none'"
      >
        <template #trigger>
          <base-button
            icon="plain/more"
            title="Address"
            alt="More"
            class="flex self-center text-eonx-neutral-800"
          />
        </template>
      </base-menu>
    </div>
    <base-loader v-if="processing" fullwidth class="absolute h-full" />
  </div>
</template>
